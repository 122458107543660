* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}
.ProgressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ProgressBar > div {
  background-color: #071a528a;
  height: 50px;
}

ul.layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 0.5rem 1rem;
}

ul.layout li {
  display: flex;
  flex-flow: column;
}
ul.layout img {
  order: -1;
}

[data-view="bigmenu"] .FullNav {
  visibility: visible;
}
[data-view="nomenu"] .FullNav {
  visibility: hidden;
}
[data-view="nomenu"] .LittleMenu button,
[data-view="nomenu"] .LittleMenu > div {
  opacity: 1;
  animation: hideIt 0.4s linear;
}

@keyframes hideIt {
  0%,
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

