html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: rgb(250, 250, 250);
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
}

body.test {
  overflow: hidden;
}

